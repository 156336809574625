<template>
  <div class="view view--parcel">
    <div class="view-header">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          :to="{ name: 'parcels' }"
          round
          grow
          icon="arrow-left"
        />
        <h1 class="page-title" v-html="`${data.designation}`"></h1>
      </div>
    </div>

    <div class="view-body">
      <Parcel v-if="parcel?.id" :parcel="parcel" :parcel_sheet="parcel_sheet" :map="true" />
    </div>

    <div class="view-footer">
      <div class="view-actions">
        <Btn
          v-if="helperService.userHasPermission('parcelle_edit')"
          :to="{ name: 'parcelEdit', params: { id: id } }"
          class="action-btn"
          text="Modifier la parcelle"
          icon="create"
          color="primary"
        />
      </div>
    </div>
  </div>

  <!-- Modals modalDelete-->
  <Modal
    title="Supprimer une parcelle&nbsp;?"
    :active="modalDelete"
    :data="data"
    @modal-close="modalDelete = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer la parcelle
        <b>{{ data.designation }}</b
        >&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modalDelete = false" />
      <Btn
        text="Supprimer"
        @click="deleteParcel()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'
import Parcel from '@/views/componentsViews/parcel/Parcel.vue'

export default {
  name: 'ParcelView',
  components: {
    Btn,
    Modal,
    Parcel,
  },

  data() {
    return {
      data: [],
      id: null,
      modalDelete: false,
      parcel: {},
      parcel_sheet: {},
    }
  },

  created() {
    this.id = Number(this.$route.params.id)
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      return this.fetchService.get(`parcelle/${this.id}`, {}).then(
        (response) => {
          this.data = response.data
          this.parcel = this.data
          this.parcel_sheet = this.data.fiche
        },
        (errorResponse) => {
          console.log('errorResponse', errorResponse)
        },
      )
    },
    deleteParcel() {
      this.emitter.emit('open-loader')
      this.fetchService.delete(`parcel/${this.id}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La parcelle a été supprimée.',
          })
          this.$router.push({ name: 'parcels' })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modalDelete = false
    },

  },
}
</script>
